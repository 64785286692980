import React from 'react';
import {graphql} from 'gatsby';
import Category from '../templates/category';

import {getFeaturedArticles} from '../utils';

const Page = ({data}) => {
  const excerpt = `Cette page n'existe pas ou plus. Mais pas de souci, voici tous nos articles !`;

  const articles = getFeaturedArticles(data);
  const siteUrl = data.site.siteMetadata.siteUrl;

  return (
    <>
      <Category
        title='Oups ! Page introuvable'
        slug='404'
        excerpt={excerpt}
        img=''
        siteUrl={siteUrl}
        articles={articles}
      />
    </>
  );
};

export default Page;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStoryblokEntry(filter: {slug: {ne: "about"}}) {
      edges {
        node {
          name
          first_published_at
          slug
          content
          parent_id
        }
      }
    }
  }
`;
